const DefaultLayout = () => import('@/layouts/website/Default.vue');
// const Index = () => import('@/pages/website/IndexPage.vue');
const NewModuleFromWorkshopInSessionPage = () => import('@/pages/website/modules/NewModuleFromWorkshopInSession.vue');
const ModuleFromWorkshopInSessionPage = () => import('@/pages/website/modules/ModuleFromWorkshopInSession.vue');
const NewModuleFromWorkshopPage = () => import('@/pages/website/modules/NewModuleFromWorkshop.vue');
const ModuleFromWorkshopPage = () => import('@/pages/website/modules/ModuleFromWorkshop.vue');
const WorkshopsIndexPage = () => import('@/pages/website/workshops/IndexPage.vue');
const WorkshopInSessionPage = () => import('@/pages/website/workshops/WorkshopInSession.vue');
const TrainingsIndexPage = () => import('@/pages/website/trainings/IndexPage.vue');
const TrainingPage = () => import('@/pages/website/trainings/Training.vue');
const SessionPage = () => import('@/pages/website/sessions/Session.vue');
const WorkshopPage = () => import('@/pages/website/workshops/Workshop.vue');
const BillingIndexPage = () => import('@/pages/website/billings/BillingIndex.vue');
const InvoiceForm = () => import('@/pages/website/billings/InvoiceForm.vue');
const AuthLayout = () => import('@/layouts/website/Auth.vue');
const Check = () => import('@/pages/website/auth/CheckPage.vue');
const SignIn = () => import('@/pages/website/auth/SignInPage.vue');
const NotFound = () => import('@/pages/website/auth/NotFoundPage.vue');
const Reset = () => import('@/pages/website/auth/ResetPage.vue');
const EmailSentReset = () => import('@/pages/website/auth/EmailSentResetPage.vue');
const EmailSentFirst = () => import('@/pages/website/auth/EmailSentFirstPage.vue');
const TheoriesIndexPage = () => import('@/pages/website/theories/IndexPage.vue');
const TheoryPage = () => import('@/pages/website/theories/Theory.vue');
const TheoryCreatePage = () => import('@/pages/website/theories/Create.vue');
const TheoryEditPage = () => import('@/pages/website/theories/Edit.vue');
const ClientsIndexPage = () => import('@/pages/website/clients/IndexPage.vue');
const ActionsIndexPage = () => import('@/pages/website/actions/IndexPage.vue');
const ActionPage = () => import('@/pages/website/actions/Action.vue');
const ClientPage = () => import('@/pages/website/clients/Client.vue');
const UsersIndex = () => import('@/pages/website/users/IndexPage.vue');
const UsersShow = () => import('@/pages/website/users/ShowPage.vue');
const MeShowPage = () => import('@/pages/website/users/MeShowPage.vue');
const PdfPage = () => import('@/pages/website/pdf/Pdf.vue');
const PrivacyPolicyPage = () => import('@/pages/website/PrivacyPolicy.vue');
const SevenerHomePage = () => import('@/pages/website/SevenerHomePage.vue');
const OtherLayout = () => import('@/layouts/website/OtherLayout.vue');
const TrainingOffersIndex = () => import ('@/pages/website/training_offers/IndexPage.vue');
const TrainingOfferPage = () => import('@/pages/website/training_offers/TrainingOffer.vue');

const routes = [
  {
    path: '/v1',
    component: {
      template: '<router-view></router-view>',
    },
    children: [
      {
        path: 'auth',
        name: 'auth',
        components: {
          default: AuthLayout,
        },
        children: [
          {
            path: '',
            name: 'auth',
            redirect: '/v1/auth/sign_in',
          },
          {
            path: 'sign_in',
            name: 'sign_in',
            components: {
              default: SignIn,
            },
          },
          {
            path: 'email_sent_reset',
            name: 'email_sent_reset',
            components: {
              default: EmailSentReset,
            },
          },
          {
            path: 'email_sent_first',
            name: 'email_sent_first',
            components: {
              default: EmailSentFirst,
            },
          },
          {
            path: 'not_found',
            name: 'not_found',
            components: {
              default: NotFound,
            },
          },
          {
            path: 'reset',
            name: 'reset',
            components: {
              default: Reset,
            },
          },
        ],
      },
      {
        path: '',
        name: 'index',
        components: {
          default: DefaultLayout,
        },
        children: [
          {
            path: '',
            name: 'home',
            redirect: '/v1/trainings',
            // components: {
            //   default: Index,
            // },
          },
          {
            path: 'trainings',
            name: 'trainings',
            components: {
              default: TrainingsIndexPage,
            },
          },
          {
            path: 'trainings/:id',
            name: 'training',
            components: {
              default: TrainingPage,
            },
          },
          {
            path: 'trainings/:id/sessions/:sessionId',
            name: 'session',
            components: {
              default: SessionPage,
            },
          },
          {
            path: 'trainings/:id/sessions/:sessionId/workshops/:workshopId',
            name: 'workshop_in_session',
            components: {
              default: WorkshopInSessionPage,
            },
          },
          {
            path: 'trainings/:id/sessions/:sessionId/workshops/:workshopId/new_module',
            name: 'new_module_from_workshop_in_session',
            components: {
              default: NewModuleFromWorkshopInSessionPage,
            },
          },
          {
            path: 'trainings/:id/sessions/:sessionId/workshops/:workshopId/modules/:moduleId',
            name: 'module_from_workshop_in_session',
            components: {
              default: ModuleFromWorkshopInSessionPage,
            },
          },
          {
            path: 'workshops',
            name: 'workshops',
            components: {
              default: WorkshopsIndexPage,
            },
          },
          {
            path: 'theories',
            name: 'theories',
            components: {
              default: TheoriesIndexPage,
            },
          },
          {
            path: 'theories/:theoryId',
            name: 'theory',
            components: {
              default: TheoryPage,
            },
          },
          {
            path: 'theories/new',
            name: 'theory_create',
            components: {
              default: TheoryCreatePage,
            },
          },
          {
            path: 'theories/edit/:id',
            name: 'theory_edit',
            components: {
              default: TheoryEditPage,
            },
          },
          {
            path: 'clients',
            name: 'clients',
            components: {
              default: ClientsIndexPage,
            },
          },
          {
            path: 'clients/:clientId',
            name: 'client',
            components: {
              default: ClientPage,
            },
          },
          {
            path: 'actions',
            name: 'actions',
            components: {
              default: ActionsIndexPage,
            },
          },
          {
            path: 'actions/:actionId',
            name: 'action',
            components: {
              default: ActionPage,
            },
          },
          {
            path: 'workshops/:workshopId',
            name: 'workshop',
            components: {
              default: WorkshopPage,
            },
          },
          {
            path: 'workshops/:workshopId/new_module',
            name: 'new_module_from_workshop',
            components: {
              default: NewModuleFromWorkshopPage,
            },
          },
          {
            path: 'workshops/:workshopId/modules/:moduleId',
            name: 'module_from_workshop',
            components: {
              default: ModuleFromWorkshopPage,
            },
          },
          {
            path: 'users',
            name: 'users',
            components: {
              default: UsersIndex,
            },
          },
          {
            path: 'users/:userId',
            name: 'users_show',
            components: {
              default: UsersShow,
            },
          },
          {
            path: 'users/me/:userId',
            name: 'users_show_me',
            components: {
              default: MeShowPage,
            },
          },
          {
            path: 'billings',
            name: 'billings',
            components: {
              default: BillingIndexPage,
            },
          },
          {
            path: 'trainings_offers',
            name: 'trainings_offers',
            components: {
              default: TrainingOffersIndex,
            },
          },
          {
            path: 'trainings_offers/:offerId',
            name: 'training_offer',
            components: {
              default: TrainingOfferPage,
            },
          },
          {
            path: 'invoice_form',
            name: 'invoice_form',
            components: {
              default: InvoiceForm,
            },
          },
          {
            path: 'pdf/:id',
            name: 'pdf_session',
            components: {
              default: PdfPage,
            },
          },
        ],
      },
      {
        path: 'other',
        name: 'other',
        components: {
          default: OtherLayout,
        },
        children: [
          {
            path: '',
            name: 'other',
            redirect: '/v1/other/privacy_policy',
          },
          {
            path: 'privacy_policy',
            name: 'privacy_policy',
            components: {
              default: PrivacyPolicyPage,
            },
          },
          {
            path: 'sevener_homepage',
            name: 'sevener_homepage',
            components: {
              default: SevenerHomePage,
            },
          },
        ]
      },
    ],
  },
];

export default routes;
