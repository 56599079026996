import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes/website';
import AuthService from '@/services/auth.service';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const user = AuthService.getUser();

  const loginNotAllowed = (!to.path.split('/').includes('auth') && !user) && !to.path.split('/').includes('other')

  if (to.path.split('/').includes('auth') && user) {
    next('/v1/trainings');
  } else if (loginNotAllowed) {
    next('/v1/auth');
  } else {
    next();
  }
});

export default router;
